import { Component, OnInit, Input } from '@angular/core';
import { VideoServis } from '../../video.servis';
import * as UIkit from 'uikit';

@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.css']
})
export class EpisodesComponent implements OnInit {
  @Input('kat') kat: any;


  constructor(private videoServis: VideoServis) { }

  ngOnInit(): void {
  }


  videoMinutes(time) {

    var minutes = time.split(":");
    minutes = (parseInt(minutes[0]) * 60) + parseInt(minutes[1]) + (parseInt(minutes[2]) > 30 ? 1 : 0);

    return minutes;
  }

  playVideo(video) {
    this.videoServis.play(video);
  }

}
