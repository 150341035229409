<div class="uk-background-secondary uk-light" uk-height-viewport="expand: true">
    <section>
        <div class="uk-section uk-padding-remove-top" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">
            <div class="uk-container-expand uk-padding">
                <div>
                    <div class="uk-flex-left">
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon" uk-icon="icon: search"></span>
                            <input #localInput required minlength="4"  class="uk-input uk-form-large" type="text"
                                (keydown.enter)="onSub($event.target.value)" placeholder="Traži..." [(ngModel)]='value'>
                        </div>
                        <small><small>* Upište traženi pojam duži od tri karaktera i pritisnite Enter</small></small>
                        <h3 *ngIf='keyWord' class="uk-heading-bullet">
                            Rezultati pretrage: <i> {{keyWord}} </i>
                        </h3>
                    </div>
                    <h4 *ngIf='noData'>Nema rezultata :(</h4>
                    <!-- uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300" -->
                    <div class="uk-section uk-padding-remove-top">
                        <ul uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 100"
                            class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-6@xl uk-grid-small uk-grid-match uk-light"
                            uk-grid uk-height-match="target: > li > app-video-elements > .uk-card">
                            <ng-container *ngFor='let kat of video'>
                                <li *ngFor="let video of kat.videos">
                                    <app-video-elements [video]="video"></app-video-elements>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-width-1-1 uk-margin-large-bottom uk-text-center">
            <h4 *ngIf='end && startup'>Nema više sadržaja</h4>
            <button *ngIf='!end && startup' class="uk-button uk-button-primary" (click)="loadMore()">Učitaj
                više...</button>
        </div>
        <div class="uk-clearfix"></div>
    </section>
</div>