<div data-uk-slider="sets: true; finite: true">
    <div class="uk-flex uk-flex-middle uk-grid uk-margin uk-grid-small">
        <!-- ovo je za kategoriju -->
        <div class="uk-flex-left uk-width-expand">
            <a class="uk-link-reset" [routerLink]="['/kategorija/'+ kat.info.slug]">
                <h3 class="uk-heading-bullet uk-margin-remove">{{kat.info.name}}</h3>
            </a>
        </div>
        <div class="uk-text-right uk-width-auto">
            <a class="" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
    <!-- ovo je za video -->
    <div class="uk-position-relative">
        <div class="uk-slider-container">
            <ul uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 10"
                class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-6@xl  uk-grid-small uk-grid-match uk-light uk-grid"
                uk-height-match="target: > li > app-video-elements > .uk-card > .uk-card-body > h5">
                <li *ngFor="let video of kat.videos; let i = index" tabindex="0">
                    <app-video-elements [video]="video"></app-video-elements>
                </li>
                <li *ngIf="kat.info.slug" class="uk-transition-toggle" tabindex="0">
                    <div class="uk-card uk-transition-toggle uk-flex uk-flex-middle uk-text-center">
                        <a class="uk-link-reset" [routerLink]="['/kategorija/'+ kat.info.slug]">
                            <div class="uk-card-body uk-padding-small uk-text-center">
                                <span uk-icon="icon: chevron-double-right; ratio: 3"></span>
                                <h5 class="uk-text-center">POGLEDAJ SVE IZ KATEGORIJE</h5>
                            </div>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <ul class="uk-visible@s uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
</div>