<div class="uk-background-secondary uk-light" uk-height-viewport="expand: true">
    <section>
        <div class="uk-section uk-padding-remove-top" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">
            <div class="uk-container-expand uk-padding">
                <div>
                    <div class="uk-child-width-1-1 uk-flex uk-flex-middle uk-grid uk-margin">
                        <div class="uk-flex-left">
                            <h3 class="uk-heading-bullet">{{name |titlecase}}</h3>
                        </div>
                    </div>
                    <!-- uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300" -->

                    <div class="uk-section uk-padding-remove-top">
                        <ul uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 100"
                            class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-6@xl uk-grid-small uk-grid-match uk-light"
                            uk-grid uk-height-match="target: > li > app-video-elements > .uk-card">
                            <ng-container *ngFor='let kat of category'>
                                <li *ngFor="let video of kat.videos">
                                    <app-video-elements [video]="video"></app-video-elements>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <app-spinner *ngIf='spinner'></app-spinner>
        </div>
        <div class="uk-width-1-1 uk-margin-large-bottom uk-text-center">
            <h4 *ngIf='end && startUp'>Nema više sadržaja</h4>
            <button *ngIf='!end && startUp' class="uk-button uk-button-primary" (click)="loadMore()">Učitaj više...</button>
        </div>
        <div class="uk-clearfix"></div>
    </section>
</div>