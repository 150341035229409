    <div #testRef [id]="'modal-'+home.id" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical uk-background-primary uk-light">
            <button class="uk-modal-close-default" type="button" uk-close  ></button>
            <div class="uk-modal-body" uk-overflow-auto>
                <h3>{{home.title}}</h3>
                <p  [innerHtml]="home.description"></p>
                <ul class="uk-list">
                    <li *ngIf="home.year">Godina: {{home.year}}.</li>
                    <li *ngIf="home.duration">Trajanje: {{home.duration}}</li>
                    <li *ngIf="home.audio">Audio: {{(home.audio) ? listShow(home.audio) : '/'}}</li>
                    <li *ngIf="home.subtitles">Titlovi: {{(home.subtitles) ? home.subtitles : '/'}}</li>
                </ul>
                <div class="">
                    <button (click)='gledaj()' class="uk-button uk-button-default uk-text-left" type="button">Gledaj <span
                            uk-icon="icon: play-circle; ratio: 1" ></span></button>
                </div>
            </div>
        </div>
    </div>
