<div class="uk-background-secondary uk-light" uk-height-viewport="expand: true">
    <section>
        <div *ngFor='let vid of video' class="uk-section uk-padding-remove-top"
            uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">
            <div class="uk-container-xlarge uk-padding uk-margin-auto">
                <div class="uk-margin-bottom uk-transition-toggle" id="playerview">
                    <div id="largeplay" class="uk-cover-container">
                        <a (click)="playerStart(vid.images.videoID)" class="playVideo" uk-scroll="{offset: 80}">
                            <img data-src={{vid.images.large}} alt="{{vid.title}}" uk-cover uk-img>
                            <canvas width="1920" height="1080"></canvas>
  
                            
                            <div class="uk-overlay-primary  uk-position-cover uk-flex uk-flex-middle uk-flex-center uk-panel uk-transition-fade">
                               
                            </div>
                            <div class="uk-position-center uk-panel">
                                <span uk-icon="icon: play-circle; ratio: 5"></span>
                            </div>
                        </a>
                    </div>
                    <div id="player"></div>
                    <!--<iframe src="https://www.youtube-nocookie.com/embed/cSGI4Mm_JIc?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=1" width="1920" height="1080" frameborder="0" allowfullscreen uk-responsive uk-video="automute: false"></iframe>-->
                </div>
                <!-- UNUTAR OVOG DIV KOJI SE NALAZI ISPOD TREBA UBACITI SLIDER -->
                <div *ngIf="vid.serial == 1" class="uk-container-xlarge uk-margin-autouk-margin-bottom">
                    <app-episodes [kat]="{info: {name: 'Epizode'}, videos: vid.episodes}"></app-episodes>
                </div>

                <div class="uk-flex uk-flex-middle uk-grid uk-margin-large-top uk-margin ">
                    <div class=" uk-width-expand uk-flex-left">
                        <h1 class="uk-heading-bullet">{{vid.title}}</h1>
                    </div>
                    <div class="uk-text-right">
                        <a href="#" class="uk-icon-link uk-hidden" uk-icon="bookmark"></a>
                    </div>
                </div>
                <div class="uk-grid ">

                    <div class="uk-width-1-1 uk-width-expand@s uk-margin-bottom">
                        <p  [innerHtml]="vid.description">{{vid.description}}</p>
                    </div>
                    <div class="uk-width-1-1 uk-width-small@s uk-width-medium@l uk-flex-first@s uk-margin-bottom">
                        <ul class=" uk-child-width-1-2 uk-child-width-1-1@s uk-grid-small uk-grid" uk-grid>
                            <li><strong>Kategorija:</strong> <a class="uk-link-reset" [routerLink]="['/kategorija/'+ vid.category_slug]"> {{vid.category_name}}</a></li>
                            <li><strong>Godina:</strong> {{vid.year}}.</li>
                            <li><strong>Trajanje:</strong> {{(vid.duration && vid.duration != "00:00:00") ? vid.duration : '/'}}</li>
                            <li><strong>Audio:</strong> {{(vid.audio) ? listShow(vid.audio) : '/' }}</li>
                            <li><strong>Titlovi:</strong> {{(vid.subtitles) ? listShow(vid.subtitles) : '/'}}</li>
                        </ul>
                    </div>

                    <div class="uk-width-1-1 uk-width-medium@m">
                        <!-- Video Arhiv - Single -->
                        <ins class="adsbygoogle"
                            style="display:block"
                            data-ad-client="ca-pub-9717359431532178"
                            data-ad-slot="5546015151"
                            data-ad-format="auto"
                            data-full-width-responsive="true"
                            data-adtest="on"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>
                    </div>

                    <div class="uk-width-1-1 uk-margin-bottom uk-flex-last">
                        <p>
                            <ng-container *ngFor="let tag of vid.tags; let i = index">
                                <a [routerLink]="['/pretraga']" (click)='tagToSearch(tag)'><span class="uk-label">{{tag}}</span></a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="uk-container-xlarge uk-margin-auto uk-padding">
                <app-videos [kat]="{info: {name: 'Povezano'}, videos: vid.rate}"></app-videos>
            </div>
        </div>
