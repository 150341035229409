import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ScreenComponent } from './screen/screen.component';
import { VideoServis } from './screen/video.servis';
import { SinglePageComponent } from './screen/single-page/single-page.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { VideosComponent } from './screen/videos/videos.component';
import { VideoElementsComponent } from './screen/videos/video-elements/video-elements.component';
import { FooterComponent } from './footer/footer.component';
import { CategoryComponent } from './screen/category/category.component';
import { PopUpComponent } from './screen/pop-up/pop-up.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchItemComponent } from './search-item/search-item.component';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { NositeComponent } from './nosite/nosite.component';
import { EpisodesComponent } from './screen/videos/episodes/episodes.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ScreenComponent,
    SinglePageComponent,
    VideosComponent,
    VideoElementsComponent,
    FooterComponent,
    CategoryComponent,
    PopUpComponent,
    SearchItemComponent,
    SpinnerComponent,
    NositeComponent,
    EpisodesComponent,

  ],

  imports: [
    FormsModule,
    BrowserModule.withServerTransition({
      appId: 'ng-universal-demystified'
    }),
    HttpClientModule,
    AppRoutingModule,
    ScrollingModule
  ],
  providers: [
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    // { provide: HTTP_INTERCEPTORS, useClass: AuthIntercepter, multi: true },
    VideoServis
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
