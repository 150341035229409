import { Component, OnInit, OnDestroy } from '@angular/core';
import { VideoServis } from './video.servis';
import {  Home } from './video.model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css']
})
export class ScreenComponent implements OnInit, OnDestroy {
  spinner: boolean = true;
  home: Home[] = [];
  unSub: Subscription;


  constructor(private videoServis: VideoServis) { }

  ngOnInit(): void {
      this.videoServis.allVideos();
      this.unSub = this.videoServis.obvHome.subscribe(resp => {
        this.home = resp;
        this.spinner = false;
        this.videoServis.setMetaTags(1);
      });
    
  }

  ngOnDestroy() {
    this.unSub.unsubscribe();
  }

}