<div class="uk-background-secondary uk-light" uk-height-viewport="expand: true">
    <section>
        <div class="uk-section uk-padding-remove-top" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">
            <div class="uk-container-expand uk-padding">
                <div>
                    <div class="uk-flex-left">
                       <h3 class="uk-heading-bullet">
                            Greška: <i> 404 </i>
                        </h3>
                    </div>
                    <h4>Stranica koju tražite nije pronađena :(</h4>
                </div>
                </div>
            </div>
     </section>
    
</div>
