import {RouterModule, Routes} from '@angular/router'
import { NgModule } from '@angular/core'
import { ScreenComponent } from './screen/screen.component'
import { SinglePageComponent } from './screen/single-page/single-page.component';
import { CategoryComponent } from './screen/category/category.component';
import { SearchItemComponent } from './search-item/search-item.component';
import { NositeComponent } from './nosite/nosite.component';

const routes: Routes = [
    // {path:'', redirectTo: '/video', pathMatch: 'full'},
    {path:'', component: ScreenComponent},
    {path:'video/:id', component: SinglePageComponent}, 
    {path:'kategorija/:name', component: CategoryComponent},
    {path:'pretraga', component: SearchItemComponent},
    {path:'404', component: NositeComponent},
    {path:'**', redirectTo: '/404'},
];
@NgModule({
 imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
 //imports: [RouterModule.forRoot(routes)],
 exports: [RouterModule]
})
export class AppRoutingModule {}