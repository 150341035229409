import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosite',
  templateUrl: './nosite.component.html',
  styleUrls: ['./nosite.component.css']
})
export class NositeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
