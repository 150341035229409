import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as uikit from 'uikit';


@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent implements OnInit {
  @Input('home') home: any;
  @ViewChild('testRef') testRef: ElementRef;


  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }


  gledaj() {
    const model = '#modal-' + this.home.id;
    this.router.navigate(['/video/' + this.home.id], { relativeTo: this.route });
    uikit.modal(model).hide();
    this.testRef.nativeElement.classList.remove("uk-open");
    this.testRef.nativeElement.classList.remove("uk-flex");
  }

  listShow(list) {

    try {
      JSON.parse(list)
  } catch (e) {
      return list;
  }
  return JSON.parse(list)

  }

}
