import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { VideoServis } from '../video.servis';
import { Params, ActivatedRoute } from '@angular/router';
import { Video } from '../video.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.css']
})

export class SinglePageComponent implements OnInit, OnDestroy {
  url: SafeResourceUrl
  id: Params
  video: Video[] = [];
  unSub: Subscription;
  urlString: string;
  player: any;
  videoToLoad: any;
  videoUn: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private videoServis: VideoServis,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.videoServis.videoById(this.id);
    });
    this.unSub = this.videoServis.obvVideo.subscribe(video => {
      this.video = video;
      this.videoServis.setMetaTags(3, this.video[0]);
      this.returnUrlFromVideo();
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlString);
    });
    this.videoUn = this.videoServis.playVideo.subscribe(video =>{
      this.playerStart(video);
    });


  }

  returnUrlFromVideo() {
    this.video.forEach(el => {
      this.urlString = el.video_url;
    });
  }

  setupPlayer(data) {
    this.videoToLoad = data;
    var YouTubePlayer = '<iframe id="player" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" src="https://www.youtube.com/embed/'+ data +'?controls=1&amp;autohide=1&amp;autoplay=1&amp;modestbranding=1&amp;enablejsapi=1&amp;iv_load_policy=3&amp;showinfo=0&amp;rel=0&amp;origin=https%3A%2F%2Fvideo.historija.ba&amp;widgetid=4&amp;enablejsapi=1" uk-video="autoplay: true" uk-responsive class="uk-responsive-width" width="1920" height="1080" frameborder="0"></iframe>';
    var VimeoPlayer = '<iframe id="player" src="https://player.vimeo.com/video/'+ data +'?autoplay=1&amp;color=997f2c&amp;title=0&amp;byline=0&amp;portrait=0" width="1920" height="1080" uk-video="autoplay: true" uk-responsive frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
   
    //https://player.vimeo.com/api/player.js
    //https://www.youtube.com/iframe_api

    switch(this.video[0].images.videoType) {
      case 'youtube':
        document.getElementById('player').outerHTML = YouTubePlayer;
        break;
      case 'vimeo':
        document.getElementById('player').outerHTML = VimeoPlayer;
        break;
    }
  }

  playerStart(data) {
    //console.log(data);
    this.videoToLoad = data;
    this.setupPlayer(data);
    document.getElementById("largeplay").classList.add("uk-hidden");
  }

  tagToSearch(tag){
    this.videoServis.sendTxt(tag);
  }

  ngOnDestroy() {
    this.unSub.unsubscribe();
  }
  

  listShow(list) {

    try {
      JSON.parse(list)
  } catch (e) {
      return list;
  }
  return JSON.parse(list)

  }

}
