import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Video, Home, Category } from './video.model';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { Params, Router } from '@angular/router';


@Injectable()
export class VideoServis {
    category: Category[] = [];
    home: Home[] = [];
    video: Video[] = []
    obvCategory = new Subject<Category[]>();
    obvHome = new Subject<Home[]>();
    obvVideo = new Subject<Video[]>();
    isEnd = new Subject<boolean>();
    isStartUp = new BehaviorSubject<boolean>(false);
    txtSearch = new BehaviorSubject<string>('');
    noData = new Subject<boolean>();
    playVideo = new Subject<string>();

    constructor(
        private http: HttpClient,
        private meta: Meta,
        private title: Title,
        private router: Router
    ) { }


    setMetaTags(type, video = null) {

        let subTitle = " | Historija.ba";
        let outTitle = "Video" + subTitle;
        let outTags = "Video arhiv, Dokumentarni film, filmovi, Historija Bosne i Hercegovine, Sport, Rat, Kultura, Koncerti";
        let outDesc = "Servis gdje možete pronaći već objavljene multimedijalne sadržaje na drugim internet servisima na jednom mjestu, a koji se odnose na historiju Bosne i Hercegovine.";
        let outImage = "";
        let outUrl = "";

        if (type == 3) {

            switch (video.category) {
                case 6:
                    subTitle = " - Koncert" + subTitle;
                    break;
                case 7:
                    subTitle = " - Predstava" + subTitle;
                    break;
                default:
                    subTitle = " - Dokumentarni film" + subTitle;
            }


            outTitle = video.title + subTitle;
            outTags = video.tags.join(", ") + ", " + video.title;
            outDesc = video.description.substring(0, 158) + "...";
            outImage = video.images.large;

        } else if (type == 2) {

            outTitle = video.info.name + " - Video" + subTitle;

        } else if (type == 1) {

            outTitle = "Početna - Video" + subTitle;

        } else {

        }

        this.title.setTitle(outTitle);
        this.meta.updateTag({ name: 'keywords', content: outTags });
        this.meta.updateTag({ name: 'description', content: outDesc });
        this.meta.updateTag({ name: 'robots', content: 'index, follow' });

        this.meta.updateTag({ name: 'og:url', content: outUrl });
        this.meta.updateTag({ name: 'og:type', content: "article" });
        this.meta.updateTag({ name: 'og:title', content: outTitle });
        this.meta.updateTag({ name: 'og:description', content: outDesc });
        this.meta.updateTag({ name: 'og:image', content: outImage });

        this.meta.updateTag({ name: 'twitter:card', content: "summary_large_image" });
        this.meta.updateTag({ name: 'twitter:site', content: "@historijaba" });
        this.meta.updateTag({ name: 'twitter:title', content: outTitle });
        this.meta.updateTag({ name: 'twitter:description', content: outDesc });
        this.meta.updateTag({ name: 'twitter:image', content: outImage });

    }

    allVideos() {
        this.http.get<{ status, message, data: Home[] }>('https://historija.ba/api/home/')
            .subscribe(videos => {
                this.home = videos.data;
                this.obvHome.next(this.home);
            });
    }

    videoById(id: Params) {
        this.http.get<{ status, message, data: Video }>('https://historija.ba/api/video/' + id)
            .subscribe(video => {
                if (!video.data) {
                    //console.log('caaaaaaaaoooooooo')
                    this.router.navigate(['404']);
                    return;
                }
                this.video.push(video.data);
                this.obvVideo.next(this.video);
                //console.log(this.video);
                this.video = [];
            });
    }

    allVideosFromCat(name: string, start: number, limit: number) {

        const link = 'https://historija.ba/api/category/slug/' + name + '/' + start + '-' + limit
        this.http.get<{ meesage, status, data: Category[] }>(link)
            .subscribe(resp => {
                if (!resp.data) {
                    this.isEnd.next(true);
                    this.noData.next(true);
                    return;
                }
                if (!resp.data || resp.data[0].videos.length < limit) {
                    this.isEnd.next(true);
                }
                this.category = resp.data;
                this.obvCategory.next(this.category);
                this.category = []
            })
    }

    searchItem(name: string, start: number, limit: number) {
        const obj = { query: name, limit: start + '-' + limit };
        this.http.post<{ status, message, data: Home[] }>('https://historija.ba/api/search/', JSON.stringify(obj))
            .subscribe(resp => {
                if (!resp.data) {
                    this.isEnd.next(true);
                    this.noData.next(true);
                    return;
                }
                if (!resp.data || resp.data[0].videos.length < limit) {
                    this.isEnd.next(true);
                }
                this.noData.next(false);
                this.home = resp.data;
                this.obvHome.next(this.home);
                this.home = [];
            });
    }

    sendTxt(message: string) {
        this.txtSearch.next(message);
    }

    play(video: string) {
        this.playVideo.next(video);
    }

}