<footer>
    <div class="uk-section uk-section-secondary uk-padding-remove-top">
        <div class="uk-container uk-text-center">
            <small>Portal Historija.ba nije vlasnik i ne raspolaže autorskim pravima video materijala koji su
                prikazani na ovoj stranici, niti je odgovorna za njihov sadržaj.<br />Ova stranica služi kao servis
                gdje možete pronaći već objavljene multimedijalne sadržaje na drugim internet servisima na jednom
                mjestu, a koji se odnose na historiju Bosne i Hercegovine.</small>
                <div class="uk-text-center uk-padding">
                    <a href="https://www.facebook.com/historija.ba" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="facebook"></a>
                    <a href="https://www.instagram.com/historija_ba/" target="_blank" class="uk-icon-button  uk-margin-small-right" uk-icon="instagram"></a>
                    <a href="https://twitter.com/historijaba" class="uk-icon-button uk-margin-small-right" target="_blank" uk-icon="twitter"></a>
                    <a href="https://www.youtube.com/nadanasnjidan" class="uk-icon-button" target="_blank" uk-icon="youtube"></a>
                </div>
        </div>
    </div>
</footer>