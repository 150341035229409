<div data-uk-slider="sets: true; finite: true">
    <div class="uk-child-width-1-2 uk-flex uk-flex-middle uk-grid uk-margin">
        <div class="uk-flex-left">
                <h3 class="uk-heading-bullet">{{kat.info.name}}</h3>
        </div>
        <div class="uk-text-right">
            <a class="" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
    <div class="uk-position-relative">
        <div class="uk-slider-container">
            <ul
                class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl uk-grid-small uk-grid-match uk-light"
                uk-height-match>
                <li class="uk-overflow-hidden" *ngFor="let video of kat.videos; let i = index" >
                    <div class="uk-card uk-transition-toggle uk-margin-small-right uk-grid-collapse" uk-grid>

                        <div class="uk-card-media-left uk-width-auto uk-cover-container">
                            <a class="uk-link-reset" (click)='playVideo(video.images.videoID)' uk-scroll="{offset: 80}">
                                <img attr.uk-img="dataSrc: {{video.images.thumb}}" data-src="" alt="{{video.title}}"
                                    uk-cover>
                                <canvas width="150" height="120"></canvas>
                                <div class="uk-position-center uk-panel">
                                    <span class="uk-transition-slide-bottom-small"
                                        uk-icon="icon: play-circle; ratio: 3"></span>
                                </div>
                            </a>
                        </div>
                        <div class="uk-width-expand">
                            <div class="uk-card-body uk-padding-small">
                                <small *ngIf="video.type">#{{video.number}} </small>
                                <h5 class="uk-margin-small uk-margin-remove-top uk-video-title"><a class="uk-link-reset"
                                        (click)='playVideo(video.images.videoID)' uk-scroll="{offset: 0}" >{{video.title}}</a></h5>
                                <div>
                                    <a (click)='playVideo(video.images.videoID)' class="uk-margin-small-right "
                                        uk-icon="icon: play-circle; ratio: 1.3"  uk-scroll="{offset: 0}" ></a>
                                    <a [href]="'#modal-'+ video.id" class="openModal" uk-toggle
                                        uk-icon="icon: info; ratio: 1.3"></a>
                                </div>
                            </div>
                            <app-pop-up [home]='video'></app-pop-up>
                        </div>
                        
                    </div>
                </li>
                <li *ngIf="kat.info.slug" class="uk-transition-toggle" tabindex="0">
                    <div class="uk-card uk-transition-toggle uk-flex uk-flex-middle uk-text-center">
                        <a class="uk-link-reset" [routerLink]="['/kategorija/'+ kat.info.slug]">
                            <div class="uk-card-body uk-padding-small uk-text-center">
                                <span uk-icon="icon: chevron-double-right; ratio: 3"></span>
                                <h5 class="uk-text-center">POGLEDAJ SVE IZ KATEGORIJE</h5>
                            </div>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <ul class="uk-visible@s uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
</div>