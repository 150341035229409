<div class="uk-card uk-transition-toggle" >
    <a class="uk-link-reset" [routerLink]="['/video/'+video.id]" >
        <div class="uk-card-media-left uk-cover-container">
             <img attr.uk-img="dataSrc: {{video.images.thumb}}" alt="{{video.title}}" uk-cover > 
            <canvas width="600" height="340"></canvas>
            <div class="uk-overlay-primary  uk-position-cover uk-flex uk-flex-middle uk-flex-center  uk-panel uk-transition-fade">
                <span class="uk-transition-slide-bottom-medium"
                    uk-icon="icon: play-circle; ratio: 4"></span>
            </div>
        </div>
    </a>
    <div class="uk-card-body uk-padding-small">

        <small *ngIf="video.serial">{{(video.serial == 1) ? 'Serijal' : videoMinutes(video.duration) +" min" }} </small>
        <small *ngIf="video.type">#{{video.number}} </small>
        <h5 class="uk-margin-small uk-margin-remove-top uk-video-title"><a
                class="uk-link-reset" [routerLink]="['/video/'+video.id]">{{video.title}}</a></h5>
        <div>
            <a [routerLink]="['/video/'+video.id]" class="uk-margin-small-right " uk-icon="icon: play-circle; ratio: 1.3"></a>
            <a [href]="'#modal-'+ video.id" class="openModal" uk-toggle
                uk-icon="icon: info; ratio: 1.3"></a>
        </div>
    </div>
    <app-pop-up [home]='video'></app-pop-up>
</div>
