<div class="uk-background-secondary uk-light">
    <header class="uk-navbar-background">
        <nav class="uk-navbar-container uk-navbar-transparent uk-light uk-padding  uk-padding-remove-vertical"
            uk-navbar>
            <div class="uk-navbar-left">
                <a [routerLink]="['/']" class="uk-link-reset"><strong>Video | Historija.ba</strong></a>
            </div>
            <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                    <li><a class="uk-search-toggle" [routerLink]="['pretraga']" uk-search-icon></a></li> 
                    <li><a target="_blank" href="https://historija.ba/">Portal</a></li> 
                </ul>
            </div>
        </nav>
    </header>
</div>