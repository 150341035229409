import { Component, OnInit, OnDestroy } from '@angular/core';
import { VideoServis } from '../video.servis';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Category } from '../video.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, OnDestroy {
  category: Category[] = [];
  end: boolean = false;
  startUp: boolean = true;
  name: string;
  unSub: Subscription;
  isEnd: Subscription;
  slug: string;
  start: number = 0;
  limit: number = 12;
  spinner: boolean = true;

  constructor(
    private videoServis: VideoServis,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params['name'];
    this.name = this.slug
    this.videoServis.allVideosFromCat(this.slug, this.start, this.limit);
    this.unSub = this.videoServis.obvCategory.subscribe(resp => {
      this.category = this.category.concat(resp);
      this.videoServis.setMetaTags(2, this.category[0]);
      this.spinner = false;
    });
    this.isEnd = this.videoServis.isEnd.subscribe(isEnd => {
      this.end = isEnd;
      this.startUp = false;
    });
  }

  loadMore() {
    this.spinner = true;
    this.start = this.start + this.limit;
    this.videoServis.allVideosFromCat(this.slug, this.start, this.limit);
    this.isEnd = this.videoServis.isEnd.subscribe(resp => {
      this.end = resp;
      this.startUp = true;
      this.spinner = false;
    });
  }

  ngOnDestroy(): void {
    this.unSub.unsubscribe();
    if (this.isEnd) {
      this.isEnd.unsubscribe();
    }
  }

}
