import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Home } from '../screen/video.model';
import { VideoServis } from '../screen/video.servis';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit, OnDestroy {
  video: Home[] = [];
  @ViewChild('localInput')localInput:ElementRef;
  keyWord: string;
  value: string;
  noData: boolean = false;
  spinner: boolean = false;
  name: string
  end: boolean = false;
  startup: boolean = false;
  isEnd: Subscription;
  videoUnsub: Subscription;
  tagUnsub: Subscription;
  noDataUnsub: Subscription;
  start: number = 0;
  limit: number = 12;

  constructor(private videoServis: VideoServis) { }

  ngOnInit(): void {
    this.tagUnsub = this.videoServis.txtSearch.subscribe(txt => {
      this.value = txt;
      if (this.value === '') {
        return;
      }
      this.onSub(this.value);
    });
    this.videoUnsub = this.videoServis.obvHome.subscribe(resp => {
      if (this.video.length === 0) {
        this.video = resp
        this.isLenght(+(this.video[0].videos.length));
      }
      else {
        this.video = this.video.concat(resp);
      }
    })
    this.isEnd = this.videoServis.isEnd.subscribe(isEnd => {
      this.end = isEnd;
    });

  }

  onSub(event:string) {
     if(event.length <= 3){
       this.localInput.nativeElement.classList.remove('ng-untouched');
       this.localInput.nativeElement.classList.add('ng-touched');
       return;
     }
    this.video = [];
    this.start = 0;
    this.keyWord = event;
    this.videoServis.searchItem(this.keyWord, this.start, this.limit);
    this.startup = false;
    this.end = false;
    this.noDataUnsub = this.videoServis.noData.subscribe(resp => {
      this.noData = resp;
    });
  }

  loadMore() {
    this.start = this.start + this.limit;
    this.videoServis.searchItem(this.keyWord, this.start, this.limit);
    this.isEnd = this.videoServis.isEnd.subscribe(resp => {
      this.end = resp;
    });
  }

  isLenght(num: number) {
    if (num == this.limit) {
      this.startup = true;
    } else {
        return;
    }
  }
  ngOnDestroy() {
    this.videoUnsub.unsubscribe();
    this.isEnd.unsubscribe();
    this.tagUnsub.unsubscribe();
    if (this.noData) {
      this.noDataUnsub.unsubscribe();
    }
    this.video = [];
    this.videoServis.txtSearch.next('');
  }
}
