import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-elements',
  templateUrl: './video-elements.component.html',
  styleUrls: ['./video-elements.component.css']
})
export class VideoElementsComponent implements OnInit {
  @Input('video') video: any;

  constructor() { }

  ngOnInit(): void {

  }


  videoMinutes(time) {

    var minutes = time.split(":");
    minutes = (parseInt(minutes[0]) * 60) + parseInt(minutes[1]) + (parseInt(minutes[2]) > 30 ? 1 : 0);

    return minutes;
  }

}
